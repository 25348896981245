<template>
  <div>
    <b-row>
      <b-col
        cols="12"
      >
        <!-- Table Container Card -->
        <b-card
          no-body
          class="mb-0"
        >
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="12"
                lg="6"
                class="
                  d-flex
                  align-items-center
                  justify-content-start
                  mb-1 mb-lg-0
                "
              >
                <v-select
                  id="status-id"
                  v-model="scheduleRestrictionFilter"
                  class="w-75 mr-1"
                  name="status-id"
                  :options="scheduleRestrictionOptions"
                  label="label"
                  item-value="value"
                  item-text="label"
                  placeholder="Restrição"
                  :clearable="false"
                />
                <v-select
                  id="classification-id"
                  v-model="classificationFilter"
                  class="w-100 mr-1"
                  name="classification-id"
                  :options="classificationOptions"
                  label="label"
                  item-value="value"
                  item-text="label"
                  placeholder="Classificação"
                >
                  <template #option="{ label, value }">
                    <feather-icon
                      icon="SmartphoneIcon"
                      size="20"
                      :color="getClassificationColor(value)"
                    />
                    <span>{{ label }}</span>
                  </template>
                  <template
                    #selected-option="{ label, value }"
                    class="d-flex align-items-center justify-content-start"
                  >
                    <feather-icon
                      icon="SmartphoneIcon"
                      size="20"
                      :color="getClassificationColor(value)"
                    />
                    <span class="d-none d-xl-inline">{{ label.length > 17 ? label.substring(0, 16) + '...' : label }}</span>
                  </template>
                </v-select>
                <v-select
                  id="status-id"
                  v-model="statusFilter"
                  class="w-75"
                  name="status-id"
                  :options="statusOptions"
                  label="label"
                  item-value="value"
                  item-text="label"
                  placeholder="Status"
                />
              </b-col>
              <b-col
                class="mt-1 mt-lg-0 d-flex align-items-center justify-content-start"
                cols="12"
                md="12"
                lg="6"
              >
                <div class="d-flex align-items-center justify-content-end w-100">
                  <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1 "
                    placeholder="Buscar..."
                    debounce="500"
                  />
                  <b-button variant="danger" @click="downloadReport">
                    <span class="text-nowrap">
                      Exportar
                    </span>
                  </b-button>
                  <b-button class="ml-1" :to="{ name: 'physician-new', }" variant="primary">
                    <span class="text-nowrap">
                      Novo Médico
                    </span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>

          <b-table
            ref="refUserListTable"
            class="position-relative table-physician"
            :items="fetchPhysicians"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="sortBy"
            show-empty
            empty-text="Nenhum registro correspondente encontrado"
            :sort-desc.sync="isSortDirDesc"
          >
            <!-- Column: User -->
            <template #cell(user)="data">
              <b-link
                :to="{
                  name: 'physician-view',
                  params: { id: data.item.id },
                }"
                class="font-weight-bold d-block text-nowrap"
              >
                <feather-icon
                  v-if="data.item.schedule_restriction"
                  :id="`schedule-restriction${data.item.id}`"
                  icon="AlertCircleIcon"
                  color="#F97794"
                  size="16"
                />
                <b-tooltip
                  v-if="data.item.schedule_restriction"
                  :target="`schedule-restriction${data.item.id}`"
                  placement="top"
                >
                  <p class="mb-0">
                    {{ data.item.schedule_restriction_reason }}
                  </p>
                </b-tooltip>
                {{ data.item.name }}
              </b-link>
            </template>

            <!-- Column: confirmed_at -->
            <template #cell(confirmed_at)="data" class="d-fex justify-content-center">
              <b-badge
                pill
                :variant="`light-${resolvePhysicianStatusVariant(data.item.confirmed_at)}`"
                class="text-capitalize"
              >
                {{ data.item.confirmed_at ? 'Ativo' : 'Inativo' }}
              </b-badge>
              <span
                :title="data.item.classification_label"
              >
                <feather-icon
                  icon="SmartphoneIcon"
                  size="20"
                  :color="getClassificationColor(data.item.classification_color)"
                />
              </span>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  :to="{
                    name: 'physician-view',
                    params: { id: data.item.id },
                  }"
                >
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">Detalhes</span>
                </b-dropdown-item>

                <b-dropdown-item
                  :to="{
                    name: 'physician-edit',
                    params: { id: data.item.id },
                  }"
                >
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Editar</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="!data.item.confirmed_at"
                  @click="sendInvite(data.item)"
                >
                  <feather-icon icon="MailIcon" />
                  <span class="align-middle ml-50">Convite</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="!data.item.confirmed_at"
                  @click="openInviteModal(data.item, false)"
                >
                  <feather-icon icon="CopyIcon" />
                  <span class="align-middle ml-50">Convite (curto)</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="!data.item.confirmed_at"
                  @click="openInviteModal(data.item, true)"
                >
                  <feather-icon icon="CopyIcon" />
                  <span class="align-middle ml-50">Convite (completo)</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="
                  d-flex
                  align-items-center
                  justify-content-center justify-content-sm-start
                "
              >
                <span class="text-muted">
                  Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas
                </span>
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="
                  d-flex
                  align-items-center
                  justify-content-center justify-content-sm-end
                "
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalUsers"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="modal-invite"
      ref="inviteModal"
      title="Convite"
      hide-footer
    >
      <b-card-text v-if="invitePhysician">
        Link de convite para <h5>{{ invitePhysician.name }}</h5>
      </b-card-text>
      <b-form-group>
        <b-input-group>
          <b-form-input
            id="invite-link"
            v-model="inviteLink"
            placeholder="Gerando"
            :disabled="!inviteLink"
          />
          <b-input-group-append>
            <b-tooltip
              ref="tooltip-hide"
              target="tooltip-button-hide-event"
              triggers="click"
            >
              Copiado!
            </b-tooltip>
            <b-button
              id="tooltip-button-hide-event"
              variant="outline-primary"
              :disabled="!inviteLink"
              @click="copyInvite"
            >
              <feather-icon
                icon="CopyIcon"
              />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BButton,
  BModal,
  VBModal,
  BFormGroup,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import usePhysicianList from './usePhysicianList'
import physicianStoreModule from '../physicianStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BModal,
    BFormGroup,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BTooltip,

    vSelect,
  },
  directives: {
    'b-modal': VBModal,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-physician'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) { store.registerModule(USER_APP_STORE_MODULE_NAME, physicianStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) { store.unregisterModule(USER_APP_STORE_MODULE_NAME) }
    })

    const inviteModal = ref(null)
    const invitePhysician = ref(null)
    const inviteLink = ref('')
    const scheduleRestrictionOptions = [
      { label: 'Todos', value: null },
      { label: 'Com restrição', value: 'yes' },
      { label: 'Sem restrição', value: 'no' },
    ]
    const paymentStatusOptions = [
      { label: 'Nunca Usado', value: 'nunca' },
      { label: 'Regular', value: 'regular' },
    ]
    const statusOptions = [
      { label: 'Ativo', value: '1' },
      { label: 'Inativo', value: '0' },
    ]
    const classificationOptions = [
      { label: 'Não confirmado', value: 'red' },
      { label: 'Ativo e não adiantou nos últimos 30 dias.', value: 'yellow' },
      { label: 'Ativo e adiantou nos últimos 30 dias.', value: 'green' },
    ]

    const generateConfirmationUrl = ({ id }, isFull) => {
      const url = isFull ? 'generateConfirmationUrlFull' : 'generateConfirmationUrl'
      return store.dispatch(`${USER_APP_STORE_MODULE_NAME}/${url}`, {
        id,
      }).then(resp => {
        inviteLink.value = resp.data.data.url
      })
    }

    const openInviteModal = (physician, isFull = false) => {
      invitePhysician.value = physician
      inviteLink.value = ''
      generateConfirmationUrl(physician, isFull)
      inviteModal.value.show()
    }

    const copyInvite = () => {
      const copyText = document.getElementById('invite-link')
      copyText.select()
      copyText.setSelectionRange(0, 99999)
      navigator.clipboard.writeText(copyText.value)
    }

    const getClassificationColor = color => {
      const colors = {
        red: '#df7273',
        yellow: '#ffb976',
        green: '#48da89',
      }
      return colors[color]
    }

    const {
      fetchPhysicians,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      sendInvite,
      downloadReport,

      // UI
      resolvePhysicianRoleVariant,
      resolvePhysicianRoleIcon,
      resolvePhysicianStatusVariant,

      // Extra Filters
      situationFilter,
      planFilter,
      statusFilter,
      classificationFilter,
      scheduleRestrictionFilter,
    } = usePhysicianList()

    return {
      scheduleRestrictionOptions,
      scheduleRestrictionFilter,
      fetchPhysicians,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      downloadReport,

      // Filter
      avatarText,

      // UI
      resolvePhysicianRoleVariant,
      resolvePhysicianRoleIcon,
      resolvePhysicianStatusVariant,

      paymentStatusOptions,
      statusOptions,

      // Extra Filters
      situationFilter,
      planFilter,
      classificationFilter,
      statusFilter,

      sendInvite,
      generateConfirmationUrl,
      openInviteModal,
      inviteModal,
      copyInvite,
      inviteLink,
      invitePhysician,
      classificationOptions,
      getClassificationColor,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.table-physician {
  min-height: 200px;
}
.physician-class {
  width: 15px;
  height: 15px;
  border-spacing: 0!important;
  margin-top: 2px;
  &:before {
    content: '';
    transform: translateY(3px);
    width: 15px;
    height: 15px;
    display: inline-block;
    border-radius: 15px;
    background-position: center center;
    background-repeat: no-repeat;
  }
  &-red {
    &:before {
      background-color: #f08182;
    }
  }
  &-yellow {
    &:before {
      background-color: #ffb976;
    }
  }
  &-green {
    &:before {
      background-color: #48da89;
    }
  }
}
@media (max-width: 1400px) {
  .v-select {
    font-size: 12px;
  }
}
.v-select {
  .physician-class {
    &:before {
      transform: translateY(2px) translateX(-4px);
    }
  }
}
.vs__selected {
  .physician-class {
    &:before {
      transform: translateY(-4px) translateX(-2px);
    }
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
